<template>
  <div class="page-profile">
    <top-bar />
    <div class="wrap-inside">
      <n-loader :loading="$var('load')" />

      <div class="header">
        <img :src="profileImg" alt="">
        <div class="container">
          <div class="content">
            <div class="item">
              <div class="label">Ваше имя</div>
              <div class="title"><b>{{ (isStore ? model.title : model.fullName) || '-' }}</b></div>
            </div>
            <div class="item">
              <div class="label">Ваш телефон</div>
              <div class="title">{{ model.phone }}</div>
            </div>
            <div class="item">
              <div class="label">Ваш Адрес</div>
              <div class="title">{{ model.address || '-' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <n-link :to="{name: 'profile.edit'}">Редактировать профиль</n-link>

        <div class="wrap">
          <div class="title"><b>Настройки</b></div>

          <div class="setting-item">
            <label class="checkbox-wrap">
              PUSH-уведомления
              <input class="custom-checkbox" type="checkbox" :checked="$app.auth.user().isPush" @change="togglePush">
              <div class="tracker">
                <div class="track"></div>
              </div>
            </label>
          </div>
          <!--          <div class="setting-item">Пароль</div>-->
          <div class="setting-item" @click="logout">Выйти <n-icon icon="logOut"></n-icon> </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profileImg from 'assets/profile.png'

export default {
  name: 'PageProfile',
  data() {
    return {
      profileImg,
    }
  },
  computed: {
    isStore() {
      return $app.auth.user().store
    },
    model() {
      const user = $app.auth.user()
      return user.store || user
    },
  },
  methods: {
    logout() {
      $app.auth.logout(false)
      this.$router.push({ name: 'index', })
    },
    togglePush() {
      $api.profile.edit({ isPush: !$app.auth.user().isPush, }).then(((response) => {
        $app.auth.user(response.data.content)
      }))
    },
  },
}
</script>

<style scoped lang="scss">
.page-profile {
  position: relative;

  .wrap-inside{
    position: relative;
  }

  .header {
    position: relative;
    min-height: 260px;
    padding: 92px 0 20px;
    margin-bottom: 4px;

    img {
      position: absolute;
      top: -60px;
      left: 0;
      max-width: 100%;
      pointer-events: none;
    }

    .content {
      padding-left: 145px;
      position: relative;

      .item{
        margin-bottom: 12px;
        &:last-of-type{
          margin-bottom: 0;
        }

        .label{
          font-size: 10px;
          color: #BABABA;
        }
      }
    }
  }

  .title{
    font-weight: 500;
    font-size: 14px;
    color: #393939;

    b{
      font-size: 18px;
      line-height: 21px;
    }
  }

  .n-link {
    background: #2A82D3;
    border-radius: 100px;
    border-color: #2A82D3;
    width: 95%;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #FFF;
    text-decoration: none;
  }

  .wrap{
    padding-top: 44px;

    .title{
      margin-bottom: 21px;
    }

    .setting-item{
      font-size: 18px;
      line-height: 21px;
      color: #BABABA;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      &:last-of-type{
        margin-bottom: 0;
      }

      .n-icon{
        margin-left: 12px;
      }
    }
  }

  .checkbox-wrap{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .custom-checkbox{
      position: absolute;
      width: 0;
      height: 0;
      right: 0;
      top: 0;

      &:checked{
        & + .tracker{
          background-color: #2a82d3;
          border: 1px solid rgba(225, 225, 225, 0.7);

          .track{
            left: 17px;
            background: #fff;
          }
        }
      }
    }

    .tracker{
      width: 41px;
      height: 24px;
      display: flex;
      background-color: #FFF;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      align-items: center;
      position: relative;
      padding: 2px;
      transition: background-color .2s ease-in-out;


      .track{
        background: rgba(0, 0, 0, 0.2);
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translate(0, -50%);
        transition: left .2s ease-in-out;
      }
    }
  }
}
</style>
